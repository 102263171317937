/* eslint-disable */
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { NavLink, useNavigate } from 'react-router-dom';
import { userSignup } from '../../redux/user/userActions';
import logo from '../../data/images/logo.jpeg';

const CreateAccount = () => {
  const userAuthenticated = localStorage.getItem('userToken');
  const { success, logged, error, loading } = useSelector(
    (state) => state.user
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      userName: '',
      clientName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      termsAgreement: false,
      rememberUser: false,
      readersList: false,
    },
    onSubmit: (values) => {
      const {
        clientName,
        userName,
        email,
        password,
        passwordConfirmation,
        rememberUser,
      } = values;
      // check if passwords match
      if (password !== passwordConfirmation) {
        alert('Password mismatch');
        return;
      }
      // localStorage.setItem('neutralAutoSignIn', JSON.stringify(rememberUser));
      dispatch(
        userSignup({
          user_name: userName,
          name: clientName,
          email: email.toLowerCase(),
          password,
        })
      )
        .unwrap()
        .then(() => {
          toast.success('You are successfully created an account.');
        })
        .catch((error) => {
          toast.error(error);
        });
    },
  });

  useEffect(() => {
    if (success) navigate('/sign-in');
    if (userAuthenticated) navigate('/dashboard');
  }, [navigate, logged, success]);

  return (
    <main className="py-20 bg-gradient-to-r from-custom-blue via-blue-500 min-h-screen flex flex-col items-center justify-center space-y-16 px-5 md:px-0">
      <div className='space-y-2'>
        <h1 className="text-white text-3xl font-bold">
          Join <span className='text-custom-blue text-transparent bg-clip-text bg-gradient-to-r from-custom-blue to-blue-700'>Neutral</span> Now
        </h1>
        <p className="text-white font-medium text-center">
          And turn your idea into reality
        </p>
      </div>
      <div className='md:w-3/6 bg-white shadow-lg relative pt-20 pb-4'>
        <div className='bg-white rounded-full p-4 w-fit absolute -top-10 sm:left-[45%] left-[40%] shadow-lg'>
          <img src={logo} alt="neutral logo" className="w-16 h-16 rounded-full object-cover" />
        </div>
        {error && <small style={{ color: 'red' }}>{error}</small>}
        <form
          className="flex flex-col space-y-6 p-4 rounded-xl w-full items-center justify-center"
          onSubmit={formik.handleSubmit}
        >
          <div className='md:w-5/6 space-y-3'>
            <input
              type="text"
              placeholder="User name"
              name="userName"
              className="p-3 rounded-md placeholder:font-medium border border-gray-400 w-full focus:outline-none focus:ring-blue-700 focus:ring-2 focus:border-none"
              value={formik.values.userName}
              onChange={formik.handleChange}
            />
            <input
              type="text"
              placeholder="Your name"
              name="clientName"
              className="p-3 rounded-md placeholder:font-medium border border-gray-400 w-full focus:outline-none focus:ring-blue-700 focus:ring-2 focus:border-none"
              value={formik.values.clientName}
              onChange={formik.handleChange}
            />
            <input
              type="text"
              placeholder="Your email"
              name="email"
              className="p-3 rounded-md placeholder:font-medium border border-gray-400 w-full focus:outline-none focus:ring-blue-700 focus:ring-2 focus:border-none"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <input
              type="Password"
              placeholder="Password"
              name="password"
              className="p-3 rounded-md placeholder:font-medium border border-gray-400 w-full focus:outline-none focus:ring-blue-700 focus:ring-2 focus:border-none"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <input
              type="Password"
              placeholder="Confirm password"
              name="passwordConfirmation"
              className="p-3 rounded-md placeholder:font-medium border border-gray-400 w-full focus:outline-none focus:ring-blue-700 focus:ring-2 focus:border-none"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
            />
          </div>
          <div className='md:w-5/6 w-full'>
            <label
              htmlFor="rememberUser"
              className="flex items-center justify-start space-x-2"
            >
              <input
                type="checkbox"
                id="rememberUser"
                name="rememberUser"
                className="mt-0 w-auto"
                value={formik.values.rememberUser}
                onChange={formik.handleChange}
              />
              <span className="">Remember me?</span>
            </label>
            <label
              htmlFor="termsAgreement"
              className="flex items-center justify-start space-x-2"
            >
              <input
                type="checkbox"
                id="termsAgreement"
                name="termsAgreement"
                className="mt-0 w-auto"
                value={formik.values.termsAgreement}
                onChange={formik.handleChange}
              />
              <span>You agree to our terms and service.</span>
            </label>
            <label htmlFor="readersList" className="flex items-center justify-start space-x-2">
              <input
                type="checkbox"
                id="readersList"
                name="readersList"
                className="mt-0 w-auto"
                value={formik.values.readersList}
                onChange={formik.handleChange}
              />
              <span>Get updates via Email.</span>
            </label>
          </div>
          <button
            disabled
            type="submit"
            className="bg-gradient-to-r from-custom-blue to-blue-400 px-4 text-white py-2 rounded-md md:w-2/6 font-semibold w-3/6"
          >
            Register
          </button>
          <p className="text-sm text-center mt-5 text-custom-gray">
            By continuing you agree to Terms and Services.
          </p>
        </form>
      </div>
      <div className='flex flex-col items-center space-y-2 text-white font-medium'>
        <NavLink to="/sign-in" className="capitalize hover:underline">
          Already have an account?
        </NavLink>
        <NavLink to="/" className="capitalize hover:underline">
          go back to home
        </NavLink>
      </div>
    </main>
  );
};

export default CreateAccount;
