import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import BASE_URL from '../../API';

export const userSignin = createAsyncThunk(
  'user/login',
  async (userinfo, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const data = await axios.post(
        `${BASE_URL}/login`,
        { user: userinfo },
        config,
      );
      const token = data.headers.authorization;
      const userInfo = data.data.data;
      return { userInfo, token };
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.response.data.error);
    }
  },
);

export const userSignup = createAsyncThunk(
  'user/signup',
  async (userinfo, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      const { data } = await axios.post(
        `${BASE_URL}/signup`,
        { user: userinfo },
        config,
      );
      return data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.response.data.status.message);
    }
  },
);

export const userLogout = createAsyncThunk('user/logout', async (token) => {
  await fetch(`${BASE_URL}/logout`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: token,
    },
  });
});
