/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../../API';

const userToken = JSON.parse(localStorage.getItem('user'))?.token;

export const getCategories = createAsyncThunk('category/getCategories', () =>
  axios
    .get(`${BASE_URL}/categories`, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);

export const getCategory = createAsyncThunk(
  'category/getCategory',
  (categoryId) =>
    axios
      .get(`${BASE_URL}/categories/${categoryId}`, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);

export const addCategory = createAsyncThunk(
  'category/addCategory',
  (category) =>
    axios
      .post(`${BASE_URL}/categories`, category, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);

export const removeCategory = createAsyncThunk(
  'category/removeCategory',
  (categoryId) =>
    axios
      .delete(`${BASE_URL}/categories/${categoryId}`, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);
export const updateCategory = createAsyncThunk(
  'category/updateCategory',
  ({ id, category }) =>
    axios
      .put(`${BASE_URL}/categories/${id}`, category, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);
