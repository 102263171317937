import React from 'react';
import { NavLink } from 'react-router-dom';
import SignInInput from './SignInInput';

const SignIn = () => (
  <main className="py-20 bg-gradient-to-r from-custom-blue via-blue-500 min-h-screen flex flex-col items-center justify-center space-y-16 px-5 md:px-0">
    <div className="space-y-2">
      <h1 className="text-white text-3xl font-bold text-center">
        Log In to
        {' '}
        <span className="text-custom-blue text-transparent bg-clip-text bg-gradient-to-r from-custom-blue to-blue-700">Neutral</span>
        {' '}
        Now
      </h1>
      <p className="text-white font-medium text-center">
        And turn your idea into reality
      </p>
    </div>
    <SignInInput />
    <div className="flex flex-col items-center space-y-2 text-white font-medium">
      <NavLink to="/create-account" className="capitalize hover:underline">
        don&apos;t have an account?
      </NavLink>
      <NavLink to="/google-signin" className="capitalize hover:underline">
        Sign in with Google
      </NavLink>
      <NavLink to="/" className="capitalize hover:underline">
        go back to home
      </NavLink>
    </div>
  </main>
);

export default SignIn;
