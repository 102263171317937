import React from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header, Footer } from './layouts';
import AppRoutes from './components/AppRoutes';

const App = () => (
  <PayPalScriptProvider options={{ 'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID }} className="z-0">
    <ToastContainer autoClose={3000} className="text-xs" />
    <Header />
    <AppRoutes />
    <Footer />
  </PayPalScriptProvider>
);

export default App;
