import React from 'react';
import './loader.css';

const Loader = () => (
  <div className="loader-cont">
    <div className="ring">
      Loading...
      <span className="ring-span" />
    </div>
  </div>
);

export default Loader;
