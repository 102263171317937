/* eslint-disable max-len */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RiMenu2Line } from 'react-icons/ri';
import { IconContext } from 'react-icons';
import { CgClose } from 'react-icons/cg';
import { userLogout } from '../../redux/user/userActions';
import logo from '../../assets/images/logo-transp.png';
import './header.scss';

const MenuLinks = [
  {
    name: 'Home',
    linkTo: '/',
  },
  {
    name: 'Services',
    linkTo: '/service',
  },
  {
    name: 'Projects',
    linkTo: '/category',
  },
  {
    name: 'About Us',
    linkTo: '/about',
  },
  {
    name: 'Contacts',
    linkTo: '/contact-us',
  },
];

const Header = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.user.token);
  const [burgerClicked, setBurgerClicked] = useState(false);
  const [colorChange, setColorchange] = useState(false);

  const handleBurgerClick = () => {
    setBurgerClicked(!burgerClicked);
    setColorchange(true);
  };

  const changeNavbarColor = () => {
    if (window.scrollY >= 70) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  return (
    <div className="header_container z-50">
      <div className={`header-scrolled  ${colorChange && 'colorChange'} d-flex flex-center`}>
        <div className="logo">
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
        </div>

        <nav className="navbar d-flex flex-center">
          <div className="hamburger-lines">
            <button
              aria-label="Opened"
              type="button"
              onClick={handleBurgerClick}
            >
              <IconContext.Provider value={{ className: 'burger_class' }}>
                {!burgerClicked ? (
                  <RiMenu2Line />
                ) : (
                  <CgClose />
                )}
              </IconContext.Provider>
            </button>
          </div>

          <ul className={`nav_menu ${burgerClicked && 'show'} d-flex flex-column`}>
            {MenuLinks.map((menu) => (
              <li key={menu.name} className="menu_item">
                <NavLink
                  to={menu.linkTo}
                  className="font-bold"
                  activeClassName="active"
                  end
                  onClick={handleBurgerClick}
                >
                  {menu.name}
                </NavLink>
              </li>
            ))}
            <div className={`nav_auth ${burgerClicked && 'show'} d-flex flex-column`}>
              {token ? (
                <li className="menu_item">
                  <NavLink
                    to="/sign-in"
                    className="font-bold"
                    activeClassName="active"
                    onClick={() => dispatch(userLogout(token))
                      .unwrap()
                      .then(() => {
                        toast.success('You logged out successfully');
                      })
                      .catch((error) => {
                        toast.error(error);
                      })}
                  >
                    Log out
                  </NavLink>
                </li>
              ) : (
                <>
                  <li>
                    <NavLink
                      to="/sign-in"
                      className="font-bold"
                      activeClassName="active"
                      onClick={handleBurgerClick}
                    >
                      Sign in
                    </NavLink>
                  </li>
                  <li className="sign-up">
                    <NavLink
                      to="/create-account"
                      className="sign-ups font-bold"
                      activeClassName="active"
                      onClick={handleBurgerClick}
                    >
                      Sign up
                    </NavLink>
                  </li>
                </>
              )}
            </div>
          </ul>

        </nav>
      </div>
    </div>
  );
};

export default Header;
