/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import {
  getCategories,
  getCategory,
  addCategory,
  removeCategory,
  updateCategory,
} from './categoryActions';

const initialState = {
  loading: false,
  error: null,
  categories: [],
  categoryDetails: {},
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {},
  extraReducers: {
    [getCategories.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categories = payload;
    },
    [getCategories.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [getCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categoryDetails = payload;
    },
    [getCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [addCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categories.push(payload);
    },
    [addCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [updateCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.categories.map((cat) => {
        if (cat.id === payload.id) {
          return {
            ...payload,
          };
        }
        return cat;
      });
    },
    [updateCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [removeCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [removeCategory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // Ask jos to render id for removed category
      // ,so you can render UI quickly instead of waiting response from the backend
      state.categories.filter((cat) => cat.id !== payload);
    },
    [removeCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export default categorySlice.reducer;
