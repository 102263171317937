/* eslint-disable */
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userSignin } from '../../redux/user/userActions';
import Loader from '../shared/Loader/Loader';
import logo from '../../data/images/logo.jpeg';

const SignInInput = () => {
  const { loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userAuthenticated = useSelector((state) => state.user.token);
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      dispatch(userSignin(values))
        .unwrap()
        .then(() => {
          toast.success('You are successfully logged in.');
        })
        .catch((error) => {
          toast.error(error);
        });
    },
  });

  useEffect(() => {
    if (userAuthenticated) {
      navigate('/dashboard');
    }
  }, [userAuthenticated, dispatch, navigate]);

  if (loading) return <Loader />;

  return (
    <section className="md:w-3/6 bg-white shadow-lg relative pt-20 pb-4 w-full">
      <div className="bg-white rounded-full p-4 w-fit absolute -top-10 md:left-[45%] left-[40%] shadow-lg">
        <img
          src={logo}
          alt="neutral logo"
          className="w-16 h-16 rounded-full object-cover"
        />
      </div>
      <form
        className="flex flex-col space-y-6 p-4 rounded-xl w-full items-center justify-center"
        onSubmit={formik.handleSubmit}
      >
        <input
          type="text"
          placeholder="Email address"
          name="email"
          className="p-3 rounded-md placeholder:font-medium border border-gray-400 w-full md:w-5/6 focus:outline-none focus:ring-blue-700 focus:ring-2 focus:border-none"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <input
          type="password"
          placeholder="Password"
          name="password"
          className="p-3 rounded-md placeholder:font-medium border border-gray-400 w-full md:w-5/6 focus:outline-none focus:ring-blue-700 focus:ring-2 focus:border-none"
          value={formik.values.password}
          onChange={formik.handleChange}
        />
        <label
          htmlFor="rememberUser"
          className="flex items-center justify-start space-x-2 text-custom-gray"
        >
          <input
            type="checkbox"
            id="rememberUser"
            name="rememberUser"
            className="mt-0 w-auto"
            // value={formik.values.rememberUser}
            // onChange={formik.handleChange}
          />
          <span>Remember me?</span>
        </label>
        <button
          disabled
          type="submit"
          className="bg-gradient-to-r from-custom-blue to-blue-400 px-4 text-white py-2 rounded-md md:w-2/6 font-semibold w-3/6"
        >
          login
        </button>
        <p className="text-sm text-center mt-5 text-custom-gray">
          By continuing you agree to Terms and Services.
        </p>
      </form>
    </section>
  );
};

export default SignInInput;
