import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineTwitter } from 'react-icons/ai';
import { TbBrandInstagram } from 'react-icons/tb';
import {
  FaFacebookF,
  FaLinkedinIn,
  FaHeart,
  FaMapMarkerAlt,
  FaSms,
} from 'react-icons/fa';
import {
  MdOutlineSubdirectoryArrowRight,
  MdPhoneInTalk,
} from 'react-icons/md';
import logo from '../../assets/images/logo-transp.png';
import './footer.scss';

const Footer = () => {
  const year = useMemo(() => {
    const date = new Date();
    const year = date.getFullYear();
    return year;
  }, []);

  return (
    <footer className="footer_section">
      <div className="footer_one">
        <ul className="footer_lists">
          <li data-aos="fade-up-right" className="footer_lists_items">
            <h3 className="item_title">About Neutral</h3>
            <div className="item_content">
              <p className="about-parag">
                We&apos;re fulfilling this vision by
                <br />
                creating new high-quality solutions for consumers and businesses
                and by driving innovation in tech industries.
              </p>
            </div>
          </li>

          <li data-aos="zoom-in-up" className="footer_lists_items">
            <h3 className="item_title">Links</h3>
            <div className="item_content">
              <p>
                <Link className="item_link" to="/">
                  <MdOutlineSubdirectoryArrowRight />
                  Home
                </Link>
              </p>
              <p>
                <Link className="item_link" to="/about">
                  <MdOutlineSubdirectoryArrowRight />
                  About Us
                </Link>
              </p>
              <p>
                <Link className="item_link" to="/service">
                  <MdOutlineSubdirectoryArrowRight />
                  Nos Services
                </Link>
              </p>
              <p>
                <Link className="item_link" to="/contact-us">
                  <MdOutlineSubdirectoryArrowRight />
                  Contact Us
                </Link>
              </p>
              <p>
                <Link className="item_link" to="/">
                  <MdOutlineSubdirectoryArrowRight />
                  Our Blog
                </Link>
              </p>
            </div>
          </li>
          <li
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            className="footer_lists_items"
          >
            <h3>Follow Us</h3>
            <div className="item_content">
              <div className="item_content">
                <p>
                  Stay up to date, stay informed
                  <br />
                  about our upcoming products...
                </p>
                <h3 className="connect">Connect</h3>
                <div className="social_media_wrapper">
                  <Link
                    href="https://www.facebook.com/satecongo?mibextid=LQQJ4d"
                    target="blank"
                    title="facebook"
                  >
                    <FaFacebookF />
                  </Link>
                  <Link to="https://twitter.com/Neutralcodes" title="instagram">
                    <TbBrandInstagram />
                  </Link>
                  <Link
                    href="https://twitter.com/Neutralcodes"
                    title="facebook"
                  >
                    <AiOutlineTwitter />
                  </Link>
                  <Link
                    href="https://www.linkedin.com/company/neutral-codes/"
                    title="linkedin"
                  >
                    <FaLinkedinIn />
                  </Link>
                </div>
              </div>
            </div>
          </li>
          <li data-aos="fade-up-right" className="footer_lists_items">
            <h3 className="item_title">Have a Questions?</h3>
            <div className="item_content">
              <p className="contact_info">
                <FaMapMarkerAlt />
                136, Av,
                <br />
                Nairobi / Kenya
              </p>
              <p className="contact_info">
                <MdPhoneInTalk />
                (+254) 791 653 805
                <br />
                (+250) 780 468 223
              </p>
              <p className="contact_info">
                <FaSms />
                support@neutral.codes
                <br />
                joskalenda@neutral.codes
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="footer_copy_right">
        <p>
          <span>
            Copyright &copy;
            {' '}
            {year}
            {' '}
            All rights reserved | Developed with
            <FaHeart />
            {' '}
            by
          </span>
          <span className="span">
            <Link to="/" title="Neutral" target="blank">
              Neutral™
              <div className="logo_icon">
                <img src={logo} alt="logo" />
              </div>
            </Link>
          </span>
        </p>
      </div>
    </footer>
  );
};

export default Footer;
