/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../../API';

const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null;

const userId = localStorage.getItem('userId');

export const getLeases = createAsyncThunk('lease/getLeases', () =>
  axios
    .get(`${BASE_URL}/users/${userId}/leases`, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);

export const getLease = createAsyncThunk('lease/getLease', (leaseId) =>
  axios
    .get(`${BASE_URL}/users/${userId}/leases/${leaseId}`, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);

export const addLease = createAsyncThunk('lease/addLease', (lease) =>
  axios
    .post(`${BASE_URL}/users/${userId}/leases`, lease, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);

export const removeLease = createAsyncThunk('lease/removeLease', (leaseId) =>
  axios
    .delete(`${BASE_URL}/users/${userId}/leases/${leaseId}`, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);
export const updateLease = createAsyncThunk('lease/updateLease', ({ leaseId, lease }) =>
  axios
    .put(`${BASE_URL}/users/${userId}/leases/${leaseId}`, lease, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);
