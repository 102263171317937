/* eslint-disable */
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import defaultState from './default/default';
import userSlice from './user/user';
import categorySlice from './category/category';
import projectSlice from './project/project';
import buySlice from './buy/buy';
import leaseSlice from './lease/lease';

const store = configureStore({
  reducer: {
    user: userSlice,
    categories: categorySlice,
    projects: projectSlice,
    buys: buySlice,
    leases: leaseSlice,
    defaultState,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger),
});

export default store;
