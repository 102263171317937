/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../../API';

const userToken = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null;

const userId = localStorage.getItem('userId');

export const getBuys = createAsyncThunk('buy/getBuys', () =>
  axios
    .get(`${BASE_URL}/users/${userId}/buys`, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);

export const getBuy = createAsyncThunk('buy/getBuy', (buyId) =>
  axios
    .get(`${BASE_URL}/users/${userId}/buys/${buyId}`, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);

export const addBuy = createAsyncThunk('buy/addBuy', (buy) =>
  axios
    .post(`${BASE_URL}/users/${userId}/buys`, buy, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);

export const removeBuy = createAsyncThunk('buy/removeBuy', (buyId) =>
  axios
    .delete(`${BASE_URL}/users/${userId}/buys/${buyId}`, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);
export const updateBuy = createAsyncThunk('buy/updateBuy', ({ buyId, buy }) =>
  axios
    .put(`${BASE_URL}/users/${userId}/buys/${buyId}`, buy, {
      headers: { Authorization: userToken },
    })
    .then((resp) => resp.data)
);
