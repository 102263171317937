/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import {
  getProjects,
  addProject,
  removeProject,
  updateProject,
  getProject,
} from './projectActions';

const initialState = {
  loading: false,
  error: null,
  projects: [],
  projectDetails: {},
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: {
    [getProjects.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProjects.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects = payload;
    },
    [getProjects.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [getProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projectDetails = payload;
    },
    [getProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects.push(payload);
    },
    [addProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.projects.map((proj) => {
        if (proj.id === payload.id) {
          return {
            ...payload,
          };
        }
        return proj;
      });
    },
    [updateProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [removeProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [removeProject.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // Ask jos to render id for removed category
      // ,so you can render UI quickly instead of waiting response from the backend
      state.projects.filter((proj) => proj.id !== payload);
    },
    [removeProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export default projectSlice.reducer;
