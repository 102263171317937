const TOKEN_EXIST = 'app/default/TOKEN_EXIST';
const REMEMBER_USER = 'app/default/REMEMBER_USER';

const setTokenStatus = (payload) => ({
  type: TOKEN_EXIST,
  payload,
});

const setAutoSignInStatus = (payload) => ({
  type: REMEMBER_USER,
  payload,
});

const defaultState = {
  tokenExist: false,
  rememberUser: false,
};

const reducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case TOKEN_EXIST:
      return {
        ...state,
        tokenExist: payload,
      };
    case REMEMBER_USER:
      return {
        ...state,
        rememberUser: payload,
      };
    default:
      return state;
  }
};

const getTokenStatus = () => async (dispatch) => {
  let tokenExist = true;
  if (
    localStorage.getItem('neutralAuthToken') === null
    || localStorage.getItem('neutralAuthToken') === ''
  ) {
    tokenExist = false;
  }
  dispatch(setTokenStatus(tokenExist));
};

const getAutoSignInStatus = () => async (dispatch) => {
  let rememberUser = true;
  if (
    localStorage.getItem('neutralAutoSignIn') === null
    || localStorage.getItem('neutralAutoSignIn') === ''
  ) {
    rememberUser = false;
  }
  dispatch(setAutoSignInStatus(rememberUser));
};

export { getTokenStatus, getAutoSignInStatus };
export default reducer;
