/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import {
  getLeases,
  getLease,
  addLease,
  removeLease,
  updateLease,
} from './leaseActions';

const initialState = {
  loading: false,
  error: null,
  leases: [],
  leaseDetails: {},
};

const leaseSlice = createSlice({
  name: 'lease',
  initialState,
  reducers: {},
  extraReducers: {
    [getLeases.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getLeases.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.leases = payload;
    },
    [getLeases.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [getLease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getLease.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.leaseDetails = payload;
    },
    [getLease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addLease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addLease.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.leases.push(payload);
    },
    [addLease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateLease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateLease.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.leases.map((lease) => {
        if (lease.id === payload.id) {
          return {
            ...payload,
          };
        }
        return lease;
      });
    },
    [updateLease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [removeLease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [removeLease.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // Ask jos to render id for removed lease
      // ,so you can render UI quickly instead of waiting response from the backend
      state.leases.filter((lease) => lease.id !== payload);
    },
    [removeLease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export default leaseSlice.reducer;
