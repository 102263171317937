/* eslint-disable react/jsx-props-no-spreading */
import React, { lazy, Suspense } from 'react';
import Spinner from '../components/shared/Spinner';

const WithLazyLoading = (Component) => {
  const LazyComponent = lazy(Component);

  const WithSuspense = (props) => (
    <Suspense fallback={<Spinner />}>
      <LazyComponent {...props} />
    </Suspense>
  );

  return WithSuspense;
};

export default WithLazyLoading;
