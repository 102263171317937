/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import {
  getBuys,
  getBuy,
  addBuy,
  removeBuy,
  updateBuy,
} from './buyActions';

const initialState = {
  loading: false,
  error: null,
  buys: [],
  buyDetails: {},
};

const buySlice = createSlice({
  name: 'buy',
  initialState,
  reducers: {},
  extraReducers: {
    [getBuys.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBuys.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.buys = payload;
    },
    [getBuys.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [getBuy.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getBuy.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.buyDetails = payload;
    },
    [getBuy.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [addBuy.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [addBuy.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.buys.push(payload);
    },
    [addBuy.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },

    [updateBuy.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateBuy.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.buys.map((buy) => {
        if (buy.id === payload.id) {
          return {
            ...payload,
          };
        }
        return buy;
      });
    },
    [updateBuy.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
    [removeBuy.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [removeBuy.fulfilled]: (state, { payload }) => {
      state.loading = false;
      // Ask jos to render id for removed buy
      // ,so you can render UI quickly instead of waiting response from the backend
      state.buys.filter((buy) => buy.id !== payload);
    },
    [removeBuy.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    },
  },
});

export default buySlice.reducer;
