/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import { userSignin, userSignup, userLogout } from './userActions';

// Store User Data in Local Storage
const user = JSON.parse(localStorage.getItem('user')) || null;
const setUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const initialState = {
  loading: false,
  userInfo: user?.userInfo,
  error: null,
  token: user?.token,
  success: false,
};

const userSlice = createSlice({
  name: 'userauth',
  initialState,
  extraReducers: {
    // user sign in
    [userSignin.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userSignin.fulfilled]: (state, { payload }) => {
      setUser(payload);
      return {
        ...state,
        ...payload,
        loading: false,
      };
    },
    [userSignin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },

    // user sign up
    [userSignup.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [userSignup.fulfilled]: (state) => {
      state.loading = false;
      state.success = true; // registration successful
    },
    [userSignup.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
      state.success = false;
    },

    // logout

    [userLogout.pending]: (state) => ({
      ...state,
      loading: true,
    }),

    [userLogout.fulfilled]: (state) => {
      setUser(null);
      return {
        ...state,
        userInfo: null,
        token: '',
        loading: false,
      };
    },

    [userLogout.rejected]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload,
    }),
  },
});

export default userSlice.reducer;
