import React from 'react';
import { Routes, Route } from 'react-router-dom';
import WithLazyLoading from '../HOC/WithLazyLoading';
import SignIn from './signin/SignIn';
import CreateAccount from './signin/CreateAccount';

const Homepage = WithLazyLoading(() => import('./homepage/Homepage'));
const Contacts = WithLazyLoading(() => import('./homepage/Contacts'));
const About = WithLazyLoading(() => import('./homepage/About'));
const Dashboard = WithLazyLoading(() => import('./dashboard/Dashboard'));
const Overview = WithLazyLoading(() => import('./dashboard/Overview'));
const Appointment = WithLazyLoading(() => import('./dashboard/Appointment'));
const OrderPage = WithLazyLoading(() => import('./orderPage/orderPage'));
const Profile = WithLazyLoading(() => import('./profile/Profile'));
const General = WithLazyLoading(() => import('./profile/Myprofile'));
const Edit = WithLazyLoading(() => import('./profile/Edit'));
const Security = WithLazyLoading(() => import('./profile/Security'));
const LetUsTalk = WithLazyLoading(() => import('./deals/LetUsTalk'));
const BuyProduct = WithLazyLoading(() => import('./deals/BuyProduct'));
const Service = WithLazyLoading(() => import('./ServicePage/Service'));
const CategoryAndProjectRoutes = WithLazyLoading(() => import('./dashboard/Category&ProjectRoutes'));
const UnAuthorized = WithLazyLoading(() => import('./UnAuthorized/UnAuthorized'));

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Homepage />} />
    <Route path="/sign-in" element={<SignIn />} />
    <Route path="/create-account" element={<CreateAccount />} />
    <Route path="/let-us-talk" element={<LetUsTalk />} />
    <Route path="/about" element={<About />} />
    <Route path="/contact-us" element={<Contacts />} />
    <Route path="/service" element={<Service />} />
    <Route path="/category/*" element={<CategoryAndProjectRoutes />} />
    <Route path="/orders" element={<OrderPage />} />
    <Route path="/buy-product" element={<BuyProduct />} />
    <Route path="/dashboard" element={<Dashboard />}>
      <Route index element={<Overview />} />
      <Route path="appointment" element={<Appointment />} />
    </Route>
    <Route path="/appointment" element={<Appointment />} />
    <Route path="/myprofile" element={<General />}>
      <Route index element={<Profile />} />
      <Route path="edit" element={<Edit />} />
      <Route path="security" element={<Security />} />
    </Route>
    <Route path="/unauthorized" element={<UnAuthorized />} />
    <Route
      path="*"
      element={(
        <p className="flex justify-center pt-5">
          There&apos;s nothing here: 404!
        </p>
      )}
    />
  </Routes>
);

export default AppRoutes;
