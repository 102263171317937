/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import BASE_URL from '../../API';

const userToken = JSON.parse(localStorage.getItem('user'))?.token;


export const getProjects = createAsyncThunk(
  'project/getProjects',
  (categoryId) =>
    axios
      .get(`${BASE_URL}/categories/${categoryId}/projects`, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);

export const getProject = createAsyncThunk(
  'project/getProject',
  ({ categoryId, projectId }) =>
    axios
      .get(`${BASE_URL}/categories/${categoryId}/projects/${projectId}`, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);

export const addProject = createAsyncThunk(
  'project/addProject',
  ({ categoryId, project }) =>
    axios
      .post(`${BASE_URL}/categories/${categoryId}/projects`, project, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);

export const removeProject = createAsyncThunk(
  'project/removeProject',
  ({ categoryId, projectId }) =>
    axios
      .delete(`${BASE_URL}/categories/${categoryId}/projects/${projectId}`, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);

export const updateProject = createAsyncThunk(
  'project/updateProject',
  ({ categoryId, projectId, project }) =>
    axios
      .put(`${BASE_URL}/categories/${categoryId}/projects/${projectId}`, project, {
        headers: { Authorization: userToken },
      })
      .then((resp) => resp.data)
);
